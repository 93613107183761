/**
 * Control for gallery
 */
const body = document.getElementsByTagName('body')[0]
let images = []
/**
 * Create html for view gallery
 *  @param {String} imgUrl - image src for open gallery
 */
const createHtml = (imgUrl) => {
    const html = `
    <div class='gallery-view'>
        <img class='gallery-img' src='${imgUrl}' alt='open image'>
        <div class='gallery-close'></div>
        <div class='gallery-previus'></div>
        <div class='gallery-next'></div>
    </div>
    `
    body.insertAdjacentHTML('afterbegin', html)
}

/**
 * Select parent (view gallery) for remove
 * @param {HTMLElement} element - event on this HTMLElement remove view gallery
 */
const removeParent = (element) => {
    element.addEventListener('click', () => {
        const galleryView = document.querySelector('.gallery-view')
        galleryView.classList.add('image-hide')
        galleryView.addEventListener('animationend', () => {
            element.parentElement.remove()
        })
    })
}

/**
 * Remove view gallery
 */
const removeGalleryView = () => {
    removeParent(document.querySelector('.gallery-close'))
    removeParent(document.querySelector('.gallery-img'))
}

/**
 * Set new image in view gallery
 * @param {HTMLElement} element - image HMTlElemnt in view gallery
 * @param {Number} url - item from array srcImg
 */
const setImg = (element, url) => {
    element.setAttribute('src', url)
}

/**
 * Previus and next image controll
 */

const controllGallery = () => {
    const galleryView = document.querySelector('.gallery-view'),
        galleryImg = document.querySelector('.gallery-img')

    galleryView.addEventListener('click', (e) => {
        const clickedElement = e.target.getAttribute('class')
        let imgIndex = 0,
            imgHref = ''

        // Find gallary-img in [images] and set to imgHref
        images.forEach((image) => {
            if (image.href.includes(galleryImg.getAttribute('src'))) {
                imgHref = image.href
            }
        })

        // // Find index imgHref in [images]
        imgIndex = images.findIndex((image) => image.href === imgHref)

        galleryImg.classList.add('image-change')
        galleryImg.addEventListener('animationend', function() {
            this.classList.remove('image-change')
        })

        if (clickedElement === 'gallery-previus') {
            imgIndex < 1
                ? setImg(galleryImg, images[images.length - 1].href)
                : setImg(galleryImg, images[imgIndex - 1].href)
        } else if (clickedElement === 'gallery-next') {
            imgIndex < images.length - 1
                ? setImg(galleryImg, images[imgIndex + 1].href)
                : setImg(galleryImg, images[0].href)
        }
    })
}

addEventListener('DOMContentLoaded', () => {
    /**
     * Get all url images from gallery to array srcImg
     */
    const gallery = document.querySelector('.gallery')
    const allImages = document.querySelectorAll('.gallery .filer-image-link')

    images = Array.from(allImages)

    /**
     * Open view gallery
     */
    if (gallery) {
        gallery.addEventListener('click', (e) => {
            e.preventDefault()

            if (e.target.classList.contains('filer-image-link')) {
                createHtml(e.target.getAttribute('href'))
            }

            if (e.target.classList.contains('filer-image-info') ||
                e.target.classList.contains('filer-image')) {
                createHtml(e.target.parentElement.getAttribute('href'))
            }

            if (e.target.classList.contains('title')) {
                createHtml(
                    e.target.parentElement.parentElement.getAttribute('href'))
            }

            removeGalleryView()
            controllGallery()
        })
    }
})
