import { setCMSBarWidth } from './cms-bar-width'


import './select_checkbox'
import './required_checkbox'
import './gallery'

document.addEventListener('DOMContentLoaded', () => {
    /* Menu control */
    const body = document.getElementsByTagName("BODY")[0]
    const toggleMenu = document.getElementById('toggleMenu')

    function menu(element, add) {
        toggleMenu.addEventListener('click', function() {
            element.classList.toggle(add)
        })
    }

    menu(body, 'show-body')

    // Because message 'Enter a valid email address.' is hidden.
    for (const email of document.querySelectorAll('.base-form input[type=email].has-error')) {
        if (email.value) {
            email.classList.add('email-error')
        }

        email.addEventListener('input', () => {
            email.classList.remove('email-error')
        })
    }


    for (const svg of document.querySelectorAll('.cooperation aside img')) {
        svg.insertAdjacentHTML('afterend', `<div class="info-tooltip" >${svg.alt}</div>`)
    }


    // Open first according collapse
    for (const collapse of document.querySelectorAll('.collapse-box')) {
        collapse.querySelector('header[id^=trigger]').classList.remove('collapsed')
        collapse.querySelector('header[id^=trigger]').setAttribute('aria-expanded', true)
        collapse.querySelector('aside[id^=container]').classList.add('collapse', 'show')
    }

    for (const item of document.querySelectorAll('.prevent-default')) {
        item.href = ''
    }

    // Add subject for link with mailto
    for (const link of document.querySelectorAll('a[href^="mailto"]')) {
        if (link.dataset.subject) {
            link.href = `${link.href}?subject=${link.dataset.subject}`
        }
    }

    // Cancel event for buttons
    for (const element of document.querySelectorAll('.cancel-event')) {
        element.addEventListener('click', (e) => {
            e.preventDefault()
        })
    }

    const structure = document.querySelector("a[href=?structure]")
    if (structure) {
        structure.addEventListener('click', () => {setTimeout(setCMSBarWidth, 1000)}, true)
    }
})
